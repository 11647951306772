exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-arabic-archive-js": () => import("./../../../src/templates/arabicArchive.js" /* webpackChunkName: "component---src-templates-arabic-archive-js" */),
  "component---src-templates-arabic-js": () => import("./../../../src/templates/arabic.js" /* webpackChunkName: "component---src-templates-arabic-js" */),
  "component---src-templates-farsi-archive-js": () => import("./../../../src/templates/farsiArchive.js" /* webpackChunkName: "component---src-templates-farsi-archive-js" */),
  "component---src-templates-farsi-js": () => import("./../../../src/templates/farsi.js" /* webpackChunkName: "component---src-templates-farsi-js" */),
  "component---src-templates-hindi-archive-js": () => import("./../../../src/templates/hindiArchive.js" /* webpackChunkName: "component---src-templates-hindi-archive-js" */),
  "component---src-templates-hindi-js": () => import("./../../../src/templates/hindi.js" /* webpackChunkName: "component---src-templates-hindi-js" */),
  "component---src-templates-indonesian-archive-js": () => import("./../../../src/templates/indonesianArchive.js" /* webpackChunkName: "component---src-templates-indonesian-archive-js" */),
  "component---src-templates-indonesian-js": () => import("./../../../src/templates/indonesian.js" /* webpackChunkName: "component---src-templates-indonesian-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-urdu-archive-js": () => import("./../../../src/templates/urduArchive.js" /* webpackChunkName: "component---src-templates-urdu-archive-js" */),
  "component---src-templates-urdu-js": () => import("./../../../src/templates/urdu.js" /* webpackChunkName: "component---src-templates-urdu-js" */)
}

